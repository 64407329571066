<template>
  <r-page-modal class="modal-allowed-capacity" remove-scrolling>
    <template #page-content>
      <h3 class="modal-title has-text-weight-black">Request Time Off</h3>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(submit)"
          class="is-label-14-px-normal"
          multipart
        >
          <div class="columns is-multiline">
            <div class="column is-12">
              <ValidationProvider
                name="time off category"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Time off category"
                  class="is-required"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-select
                    aria-role="list"
                    placeholder="Select category..."
                    v-model="data.timeoff"
                    :expanded="true"
                  >
                    <option
                      v-for="option in timeoffList"
                      :value="option.id"
                      :key="'employment-' + option.id"
                    >
                      {{ option.name }}
                    </option>
                  </b-select>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12 request-section-radio-tooltip">
              <ValidationProvider
                name="start_date"
                v-slot="{ errors }"
                rules="required"
              >
                <b-tooltip position="is-top" class="start-date-tooltip-info">
                  <b-icon
                    icon="information"
                    size="is-small"
                    class="tooltip-date-icon"
                  />
                  <template v-slot:content>
                    <div class="is-flex">
                      This is the first day we take time off
                    </div>
                  </template>
                </b-tooltip>
                <b-field
                  label="Start Date"
                  class="is-required"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-datepicker
                    v-model="data.start_date"
                    placeholder="Select a date..."
                    :min-date="minDate"
                    :max-date="maxDate"
                  />
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12 request-section-radio-tooltip">
              <ValidationProvider
                name="end_date"
                v-slot="{ errors }"
                rules="required|date_higher_than:@start_date"
              >
                <b-tooltip position="is-top" class="end-date-tooltip-info">
                  <b-icon
                    icon="information"
                    size="is-small"
                    class="tooltip-date-icon"
                  />
                  <template v-slot:content>
                    <div class="is-flex">
                      This is the last day we take time off
                    </div>
                  </template>
                </b-tooltip>
                <b-field
                  label="End Date"
                  class="is-required"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-datepicker
                    v-model="data.end_date"
                    placeholder="Select a date..."
                    :min-date="data.start_date"
                    :max-date="maxDate"
                  />
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12 request-section-radio-tooltip">
              <ValidationProvider
                name="additional approver"
                v-slot="{ errors }"
                rules="required"
              >
                <b-tooltip position="is-top" class="tooltip-info">
                  <b-icon
                    icon="information"
                    size="is-small"
                    class="tooltip-date-icon"
                  />
                  <template v-slot:content>
                    <div class="is-flex">
                      After searching your approver, please select the user in
                      the list.
                    </div>
                  </template>
                </b-tooltip>
                <b-field
                  label="PO or SM Approval"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-autocomplete
                    v-model="searchUser"
                    :data="userList"
                    placeholder="Search PO or SM User"
                    field="fullName"
                    :loading="isFetchingUser"
                    :check-infinite-scroll="true"
                    icon-right="account"
                    @typing="getUserList"
                    @select="selectUser"
                    @infinite-scroll="getMoreUserList"
                  >
                    <template slot-scope="props">
                      <div class="media">
                        <div class="media-left">
                          <div v-if="props.option.profilePictureUrl">
                            <img
                              class="request-profile-picture"
                              width="32"
                              :src="props.option.profilePictureUrl"
                            />
                          </div>
                          <div v-else>
                            <img
                              class="request-profile-picture"
                              v-if="props.option.gender === 'F'"
                              width="32"
                              src="/images/default-profile-picture-female.png"
                            />

                            <img
                              class="request-profile-picture"
                              v-else
                              width="32"
                              src="/images/default-profile-picture-male.png"
                            />
                          </div>
                        </div>
                        <div class="media-content">
                          {{ props.option.fullName }}
                          <br />
                          <small>
                            {{
                              props.option.positionName
                                ? props.option.positionName
                                : '-'
                            }}
                          </small>
                        </div>
                      </div>
                    </template>
                  </b-autocomplete>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-12" v-if="data.image == null">
              <b-field label="Upload File">
                <b-upload
                  v-model="data.image"
                  accept="image/*"
                  drag-drop
                  expanded
                  m
                >
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon icon="image" size="is-large"></b-icon>
                      </p>
                      <p>
                        Drag image here or
                        <span class="has-red-text">Browse</span>
                      </p>
                    </div>
                  </section>
                </b-upload>
              </b-field>
            </div>
            <div class="column is-4" v-if="data.image != null">
              <label class="label">File to upload</label>
            </div>
            <div class="column is-4" v-if="data.image != null">
              <div class="tag is-primary">
                <span class="file-tag">
                  {{ data.image.name }}
                </span>

                <button
                  class="delete is-small"
                  type="button"
                  @click="deleteDropFile()"
                ></button>
              </div>
            </div>
            <div class="column is-12">
              <ValidationProvider
                name="request reason"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Request Reason"
                  class="is-required"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-input
                    type="textarea"
                    v-model="data.request_reason"
                    placeholder="Please write your message"
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column">
              <b-button
                native-type="submit"
                expanded
                :loading="isLoading"
                type="is-primary"
              >
                Request Time Off
              </b-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </r-page-modal>
</template>
<script>
import ApiService from '@/services/common/api.service.js'
import { mapActions, mapGetters } from 'vuex'
import { statusRequest } from '@/constant'
import { showToast } from '@/services/util'
import moment from 'moment'
import debounce from 'lodash/debounce'

export default {
  data() {
    const today = new Date()
    return {
      data: {
        id: '',
        user: null,
        timeoff: null,
        start_date: null,
        end_date: null,
        image: null,
        status: null,
        request_reason: '',
        decline_reason: '',
        additionalApproverId: null,
      },
      statusRequest: statusRequest,
      userList: [],
      timeoffList: [],
      isLoading: false,
      isFetchingUser: false,
      isFetchingTimeoff: false,
      minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      maxDate: new Date(
        today.getFullYear() + 100,
        today.getMonth(),
        today.getDate()
      ),

      searchUser: '',
      pageUserList: 1,
      lastPageUserList: 1,
      selectedUser: '',
    }
  },
  async mounted() {
    await this.$store.dispatch('user/loadUserFromToken')
  },
  computed: {
    ...mapGetters({
      currentUser: 'user/getUserInfo',
    }),
  },
  created() {
    this.getFilterTimeoff()
  },
  methods: {
    ...mapActions({
      saveTimeoff: 'timeOffModule/saveTimeoff',
    }),

    // Filter timeoff
    getFilterTimeoff() {
      this.isFetchingTimeoff = true

      ApiService.get('api/space-roketin/time-off/get-filter-time-off')
        .then((response) => {
          this.timeoffList = []
          response.data.forEach((item) => this.timeoffList.push(item))
        })
        .catch(() => {
          this.timeoffList = []
        })
        .finally(() => {
          this.isFetchingTimeoff = false
        })
    },

    openFileURL(fileUrl) {
      window.open(`${this.backendUrl}${fileUrl}`)
    },
    deleteDropFile() {
      this.data.image = null
    },

    async submit() {
      this.isLoading = true
      let form = new FormData()

      try {
        if (this.currentUser !== null) {
          form.append('user_id', this.currentUser.id)
          form.append('status', 'waiting')
        }

        if (this.data.image) {
          form.append('images[]', this.data.image)
        }

        form.append(
          'start_date',
          moment(this.data.start_date).format('YYYY-MM-DD')
        )
        form.append('end_date', moment(this.data.end_date).format('YYYY-MM-DD'))
        form.append('timeoff_type_id', this.data.timeoff)
        form.append('request_reason', this.data.request_reason)

        if (!this.data.additionalApproverId) {
          showToast(
            "Either the PO or SM approver hasn't yet been selected or the user is not found.",
            'is-danger',
            'is-top'
          )
          this.isSubmitting = false
          return
        }
        form.append('additional_approver_id', this.data.additionalApproverId)

        await this.saveTimeoff(form)
        showToast('Add Success', 'is-success', 'is-top')
      } catch (e) {
        showToast(e.response.data.message, 'is-danger', 'is-top')
      }

      this.isLoading = false

      this.resetForm()
      this.$emit('close-modal')
    },

    resetForm() {
      this.data = {
        ...{
          id: '',
          user: null,
          timeoff: null,
          startDate: null,
          endDate: null,
          status: null,
          request_reason: '',
          decline_reason: '',
          images: [],
          additionalApproverId: null,
        },
      }
      this.searchUser = null
      this.selectedUser = null
    },

    selectUser(option) {
      if (option) {
        this.selectedUser = option
        this.data.additionalApproverId = option.id
        console.log(this.data.additionalApproverId)
      }
    },

    getUserList: debounce(function (name) {
      this.data.additionalApproverId = null
      if (this.selectedUser !== name) {
        this.selectedUser = name
        this.userList = []
        this.pageUserList = 1
        this.lastPageUserList = 1
      }
      if (!name.length) {
        this.userList = []
        this.pageUserList = 1
        this.lastPageUserList = 1
        return
      }
      if (this.pageUserList > this.lastPageUserList) {
        return
      }
      this.isFetchingUser = true

      ApiService.get('/api/space-roketin/user/v3', {
        search: this.selectedUser,
        page: this.pageUserList,
      })
        .then((response) => {
          response.data.data.forEach((item) => this.userList.push(item))
          this.pageUserList++
          this.lastPageUserList = response.data.meta.lastPage
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          this.isFetchingUser = false
        })
    }, 500),
    getMoreUserList: debounce(function () {
      this.getUserList(this.selectedUser)
    }, 250),
  },
}
</script>
